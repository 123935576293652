import Vue, { VNode } from "vue";
import BootstrapVue from "bootstrap-vue";
import Vuelidate from "vuelidate";

import VueMask from "v-mask";
import VueRouter from "vue-router";
import vco from "v-click-outside";
import router from "./router/index";
import Scrollspy from "vue2-scrollspy";

import "../src/design/app.scss";

import store from "@/state/store";

import App from "./App.vue";

import i18n from "./i18n";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fab);
library.add(fas);
library.add(far);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(VueRouter);
Vue.use(vco);
Vue.use(Scrollspy);
// eslint-disable-next-line @typescript-eslint/no-var-requires
const VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo);
Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(VueMask);

new Vue({
  router,
  store,
  i18n,
  render: (h): void | VNode | null => h(App),
}).$mount("#app");
