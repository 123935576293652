import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import store from "@/state/store";
import { KcmProfile, ProfileApiError, ProfileApiResponse } from "@/types";
//import store from "../../state/store";
const baseUrl = process.env.VUE_APP_P_API_ROOT
  ? process.env.VUE_APP_P_API_ROOT.replace(/\/$/, "")
  : "";
/**
 * Define the keys in the env file used for different
 * access levels
 */
/* const AccessLevels = {
  standard: process.env.VUE_APP_CONTENT_API_KEY,
}; */
/**
 * Define a list of known errors when working with
 * the content api
 */
const Errors = {
  PersonalizationErr: "unable to load personalization profile",
};
const headers: AxiosRequestConfig = {
  auth: { username: process.env.VUE_APP_P_API_KEY ?? "", password: "" },
};
//const INDUSTRY = 1;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function get(resource: string): Promise<AxiosResponse<any, any>> {
  return axios.get(baseUrl + resource, headers);
}

export const pService = {
  getPersonalization,
};

function getPersonalization(
  userID = "0"
): Promise<KcmProfile | ProfileApiError> {
  if (userID === "0") {
    return Promise.resolve({ error: Errors.PersonalizationErr });
  }
  store.dispatch("pp/setLoadingPP", true);
  const endpoint = "/" + userID;

  return get(endpoint)
    .then((result: ProfileApiResponse) => {
      if (result.data?.profiles) {
        result.data.profiles.updatedAt = result.data.last_update?.blog;
        return result.data.profiles;
      } else {
        return { error: "No user found matching these parameters" };
      }
    })

    .catch(() => {
      return { error: Errors.PersonalizationErr };
    })
    .finally(() => {
      store.dispatch("pp/setLoadingPP", false);
    });
}
