import store from "@/state/store";
import { contentService } from "@/helpers/api-services/content.service";
import { Dictionary, Route } from "vue-router/types/router";

declare interface RouteParams {
  [key: string]:
    | string
    | number
    | undefined
    | Dictionary<string | (string | null)[]>;
}

function redirectRss(to: Route): void {
  const ppCode = store.getters["pp/ppCode"];
  // const status = store.getters["auth/authStatus"];

  const category = to.params.category ? to.params.category : "";
  const endpoint = contentService.getRss(ppCode, category, to.params.lang);

  const baseUrl = process.env.VUE_APP_CONTENT_API_ROOT
    ? process.env.VUE_APP_CONTENT_API_ROOT.replace(/\/$/, "")
    : "";
  // const finalUrl = status != "inactive"? baseUrl + endpoi

  window.location.replace(baseUrl + endpoint);
}

export default [
  {
    path: "/:lang([eE][snSN])/",
    name: "home",
    component: (): Promise<typeof import("./views/home-view.vue")> =>
      import("./views/home-view.vue"),
    props: true,
  },
  {
    path: "/:lang([eE][snSN])/page/:page(\\d+)",
    name: "kcm-blog-navigation",
    component: (): Promise<typeof import("./views/home-view.vue")> =>
      import("./views/home-view.vue"),
    props: (route: Route): RouteParams => {
      return {
        ...route.params,
        ...{ page: parseInt(route.params.page) || undefined },
      };
    },
  },
  {
    path: "/:lang([eE][snSN])/:type(buyers|sellers|fthb|buyersty|sellersty|fthbty)/",
    name: "guide",
    component: (): Promise<typeof import("./views/lead-capture-page.vue")> =>
      import("./views/lead-capture-page.vue"),
    props: true,
    meta: { pauseStatus: false },
  },
  {
    path: "/:lang([eE][snSN])/privacy-policy/",
    name: "privacy-policy",
    component: (): Promise<typeof import("./views/privacy-policy-page.vue")> =>
      import("./views/privacy-policy-page.vue"),
    props: true,
  },
  // Maybe in the future get a list of categories and make the category regex (c1|c2|c3|...etc).
  {
    path: "/:lang([eE][snSN])/category/:category([a-zA-Z-]+)/feed",
    name: "rss-category",
    beforeEnter: redirectRss,
    component: (): Promise<typeof import("./views/rss-page.vue")> =>
      import("./views/rss-page.vue"),
    props: true,
  },
  {
    path: "/:lang([eE][snSN])/category/:category1([a-zA-Z-]+)/:category([a-zA-Z-]+)/feed",
    name: "rss-category-sub",
    beforeEnter: redirectRss,
    component: (): Promise<typeof import("./views/rss-page.vue")> =>
      import("./views/rss-page.vue"),
    props: true,
  },
  {
    path: "/:lang([eE][snSN])/category/:slug([a-zA-Z-]+)/(page/)?:page(\\d+)?",
    name: "category",
    component: (): Promise<typeof import("./views/category-page.vue")> =>
      import("./views/category-page.vue"),
    props: (route: Route): RouteParams => {
      return {
        ...route.params,
        ...{ page: parseInt(route.params.page) || undefined },
      };
    },
  },
  {
    path: "/:lang([eE][snSN])/category/:category1([a-zA-Z-]+)/:slug([a-zA-Z-]+)/(page/)?:page(\\d+)?",
    name: "category-sub",
    component: (): Promise<typeof import("./views/category-page.vue")> =>
      import("./views/category-page.vue"),
    props: (route: Route): RouteParams => {
      return {
        ...route.params,
        ...{ page: parseInt(route.params.page) || undefined },
      };
    },
  },

  {
    path: "/:lang([eE][snSN])/videos/embed/:pp_code/:videoId",
    name: "embed",
    component: (): Promise<typeof import("./views/embed-page.vue")> =>
      import("./views/embed-page.vue"),
    props: true,
  },

  {
    path: "/:lang([eE][snSN])/videos/:slug",
    name: "kcm-single-video-page",
    component: (): Promise<typeof import("./views/single-video-page.vue")> =>
      import("./views/single-video-page.vue"),
    props: true,
    meta: { featureRequired: "video" },
  },
  {
    path: "/:lang([eE][nN])/videos/",
    name: "kcm-video-home-page",
    component: (): Promise<typeof import("./views/videos-home-page.vue")> =>
      import("./views/videos-home-page.vue"),
    props: true,
    meta: { featureRequired: "video" },
  },
  {
    path: "/:lang([eE][snSN])/search",
    name: "search-default",
    component: (): Promise<typeof import("./views/search-page.vue")> =>
      import("./views/search-page.vue"),
    props: true,
  },
  {
    path: "/:lang([eE][snSN])/search/page/:page(\\d+)",
    name: "search-page",
    component: (): Promise<typeof import("./views/search-page.vue")> =>
      import("./views/search-page.vue"),
    props: (route: Route): RouteParams => {
      return {
        ...route.params,
        ...{ page: parseInt(route.params.page) || undefined },
      };
    },
  },
  {
    path: "/:lang([eE][snSN])/feed",
    name: "rss-feed",
    beforeEnter: redirectRss,
    props: true,
  },
  {
    path: "/:lang([eE][snSN])/:year(\\d{4})/:month(\\d{1,2})/:day(\\d{1,2})/:slug",
    name: "kcm-single-blog-page",
    component: (): Promise<typeof import("./views/single-blog-page.vue")> =>
      import("./views/single-blog-page.vue"),
    props: true,
  },
  {
    path: "/:lang([eE][snSN])/:year(\\d{4})/:month(\\d{1,2})/:day(\\d{1,2})",
    name: "archive-day-page",
    component: (): Promise<typeof import("./views/category-page.vue")> =>
      import("./views/category-page.vue"),
    props: true,
  },
  {
    path: "/:lang([eE][snSN])/:year(\\d{4})/:month(\\d{1,2})",
    name: "archive-month-page",
    component: (): Promise<typeof import("./views/category-page.vue")> =>
      import("./views/category-page.vue"),
    props: (route: Route): RouteParams => {
      return {
        ...route.params,
        ...{ page: undefined },
      };
    },
  },
  {
    path: "/:lang([eE][snSN])/:year(\\d{4})/:month(\\d{1,2})/page/:page(\\d+)",
    name: "archive-month-page-paginated",
    component: (): Promise<typeof import("./views/category-page.vue")> =>
      import("./views/category-page.vue"),
    props: (route: Route): RouteParams => {
      return {
        ...route.params,
        ...{ page: parseInt(route.params.page) || undefined },
      };
    },
  },
  {
    path: "/:lang([eE][snSN])/:year(\\d{4})",
    name: "archive-year-page",
    component: (): Promise<typeof import("./views/category-page.vue")> =>
      import("./views/category-page.vue"),
    props: (route: Route): RouteParams => {
      return {
        ...route.params,
        ...{ page: undefined },
      };
    },
  },
  {
    path: "/:lang([eE][snSN])/:year(\\d{4})/page/:page(\\d+)",
    name: "archive-year-page-paginated",
    component: (): Promise<typeof import("./views/category-page.vue")> =>
      import("./views/category-page.vue"),
    props: (route: Route): RouteParams => {
      return {
        ...route.params,
        ...{ page: parseInt(route.params.page) || undefined },
      };
    },
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: "/:lang([eE][snSN])/*",
    redirect: (to: Route): RouteParams => {
      const query = to.query;
      query.o = "oops";
      return { name: "search-default", query: query };
    },
  },
];
