import { KcmCategory } from "@/types";
import { Route } from "vue-router";

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
let gtag: any = null;

export const GoogleTrackingService = {
  gtagFocusBlur,
  modifyGoogleTagManager,
  pageView,
};

/**
 * Sets up the gtag variable in this file
 */
function init(): void {
  try {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    gtag = window.gtag;
  } catch (err) {
    console.warn("Failed to initialize GA: ", err);
  }
}

/**
 * Tracks a pageview in GA
 * @param to the path that was navigated to
 */
function pageView(data: { to: Route }): void {
  try {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    if (gtag == null) {
      init();
    }

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    gtag("event", "page_view", {
      page_path: data.to.fullPath,
      page_title: data.to.name,
    });
  } catch (err) {
    console.log("Failed to track pageview with GA: ", err);
  }
}

// One day I will type this...
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function gtagFocusBlur(event: any, typeString: string): any {
  const input = event.target;

  if (input) {
    const inputID = input.id || "(no input ID)";
    const inputName = input.name || "(no input name)";
    const inputClass = input.class || "(no input class)";

    const form = input.form;
    const formID = form.id || "(no form ID)";
    const formName = form.name || "(no form name)";
    const formClass = form.class || "(no form class)";

    window.dataLayer.push({
      event:
        typeString === "focus"
          ? "gtm4wp.formElementEnter"
          : "gtm4wp.formElementLeave",

      inputID: inputID,
      inputName: inputName,
      inputClass: inputClass,

      formID: formID,
      formName: formName,
      formClass: formClass,
    });
  }
}

//
function modifyGoogleTagManager(
  name: string,
  categories = [] as Partial<KcmCategory>[],
  searchTerm?: string,
  searchResults?: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  const script = document.getElementById("GoogleTagManagerEditScript");
  let categoriesSlugs = "";
  if (categories.length > 0)
    categoriesSlugs = JSON.stringify(
      categories.map((category) => {
        return category.slug;
      })
    );

  if (script) {
    if (name === "default") {
      script.innerHTML =
        "//<![CDATA[\r\n" +
        "var dataLayer_content = {'pagePostType':'frontpage'};" +
        "dataLayer.push( dataLayer_content );//]]>";
    }
    if (name === "single-blog" || name === "single-video") {
      script.innerHTML =
        "//<![CDATA[\r\n" +
        "var dataLayer_content = {'pagePostType':'post','pagePostType2':'single-post','pageCategory':" +
        categoriesSlugs +
        ",'pagePostAuthor':'KCM Crew'};" +
        "dataLayer.push( dataLayer_content );//]]>";
    }
    if (name === "category") {
      script.innerHTML =
        "//<![CDATA[\r\n" +
        "var dataLayer_content = {'pagePostType':'post','pagePostType2':'category-post','pageCategory':" +
        categoriesSlugs +
        "};" +
        "dataLayer.push( dataLayer_content );//]]>";
    }
    if (name === "guide" || name === "videos" || name === "privacy") {
      script.innerHTML =
        "//<![CDATA[\r\n" +
        "var dataLayer_content = {'pagePostType':'page','pagePostType2':'single-page','pagePostAuthor':'simplify'};" +
        "dataLayer.push( dataLayer_content );//]]>";
    }
    if (name === "day") {
      script.innerHTML =
        "//<![CDATA[\r\n" +
        "var dataLayer_content = {'pagePostType':'post','pagePostType2':'day-post'};" +
        "dataLayer.push( dataLayer_content );//]]>";
    }
    if (name === "month") {
      script.innerHTML =
        "//<![CDATA[\r\n" +
        "var dataLayer_content = {'pagePostType':'post','pagePostType2':'month-post'};" +
        "dataLayer.push( dataLayer_content );//]]>";
    }
    if (name === "year") {
      script.innerHTML =
        "//<![CDATA[\r\n" +
        "var dataLayer_content = {'pagePostType':'post','pagePostType2':'year-post'};" +
        "dataLayer.push( dataLayer_content );//]]>";
    }
    if (name === "search") {
      script.innerHTML =
        "//<![CDATA[\r\n" +
        "var dataLayer_content = {'siteSearchTerm':'" +
        searchTerm +
        "','siteSearchFrom':'','siteSearchResults':" +
        searchResults +
        "};" +
        "dataLayer.push( dataLayer_content );//]]>";
    }
  }
}
